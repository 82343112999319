<template>
  <div class="result">
    <div class="container result__container">
      <div class="result__top" v-if="getResult.risk">
        <h2 class="result__title">Ваш ризик:</h2>
        <div class="result__risk">
          <div
              class="result__risk-low result__risk-item"
              :class="{ active: getResult.risk === 'L' }"
          >
            <p>Низький</p>
          </div>
          <div
              class="result__risk-medium result__risk-item"
              :class="{ active: getResult.risk === 'M' }"
          >
            <p>ПОМІРНИЙ</p>
          </div>
          <div
              class="result__risk-high result__risk-item"
              :class="{ active: getResult.risk === 'H' }"
          >
            <p>високий</p>
          </div>
        </div>
      </div>

      <div class="result__content">
        <div class="result__item" v-bind:class="[{ mt: getResult.type === 'G' }]">
          <div class="result__pack" v-if="getResult.type === 'BC'">
            <img
                srcset="
                @/assets/img/result-2p(x2).png 1024w,
                @/assets/img/result-2p(x3).png 1920w
              "
                sizes="(max-width: 640px) 200px,
                          (max-width: 1024px) 250px,
                          272px"
                src="@/assets/img/result-2p(x1).png"
                alt=""
                decoding="async"
                loading="lazy"
                class="result__img result__img--bc"
            />

            <div class="result__buttons">
              <a
                  href="https://tabletki.ua/Cito-Test/filter/40=5297%2C4170/"
                  class="result__link"
                  target="_blank"
              >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                      fill="#F8391F"
                  />
                </svg>
                <p>
                  <nobr>Знайти в аптеці</nobr>
                </p>
              </a>
              <a
                  href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173225&promocode=frmsk&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hepatit_b_c"
                  class="result__btn"
                  target="_blank"
              >
                <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                      fill="#f3e6a1"
                  />
                  <path
                      d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                      stroke="#f3e6a1"
                  />
                </svg>
                <p>
                  Купити<br/>
                  онлайн
                </p>
              </a>
            </div>
          </div>

          <div class="result__pack" v-if="getResult.type === 'C'">
            <img
                srcset="
                @/assets/img/Cito_Test_Gepatitis_С_2.png 1024w,
                @/assets/img/Cito_Test_Gepatitis_С_3.png 1920w
              "
                sizes="(max-width: 640px) 200px,
                          (max-width: 1024px) 250px,
                          272px"
                src="@/assets/img/Cito_Test_Gepatitis_С_1.png"
                alt=""
                decoding="async"
                loading="lazy"
                class="result__img"
            />

            <div class="result__buttons">
              <a
                  href="https://tabletki.ua/uk/Cito-Test-HCV/1005184/"
                  class="result__link"
                  target="_blank"
              >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                      fill="#F8391F"
                  />
                </svg>
                <p>
                  <nobr>Знайти в аптеці</nobr>
                </p>
              </a>
              <a
                  href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173223&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hepatit_c"
                  class="result__btn"
                  target="_blank"
              >
                <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                      fill="#f3e6a1"
                  />
                  <path
                      d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                      stroke="#f3e6a1"
                  />
                </svg>
                <p>
                  Купити<br/>
                  онлайн
                </p>
              </a>
            </div>
          </div>

          <div class="result__pack" v-if="getResult.type === 'B'">
            <img
                srcset="
                @/assets/img/banner_pack-1(x2).png 1024w,
                @/assets/img/banner_pack-1(x3).png 1920w
              "
                sizes="(max-width: 640px) 200px,
                          (max-width: 1024px) 250px,
                          272px"
                src="@/assets/img/banner_pack-1(x1).png"
                alt=""
                decoding="async"
                loading="lazy"
                class="result__img"
            />

            <div class="result__buttons">
              <a
                  href="https://tabletki.ua/uk/Cito-Test-HBsAg/1010733/"
                  class="result__link"
                  target="_blank"
              >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                      fill="#F8391F"
                  />
                </svg>
                <p>
                  <nobr>Знайти в аптеці</nobr>
                </p>
              </a>
              <a
                  href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173220&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hepatit_b"
                  class="result__btn"
                  target="_blank"
              >
                <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                      fill="#f3e6a1"
                  />
                  <path
                      d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                      stroke="#f3e6a1"
                  />
                </svg>
                <p>
                  Купити<br/>
                  онлайн
                </p>
              </a>
            </div>
          </div>

          <div class="result__buble" v-if="getResult.type === 'G'">
            <img
                src="@/assets/img/result-gm.svg"
                alt=""
                decoding="async"
                loading="lazy"
                class="result__buble-img"
            />
          </div>

          <div class="result__16" v-if="getResult.type === '<16'">
            <img
                src="@/assets/img/test-16.svg"
                alt=""
                decoding="async"
                loading="lazy"
                class="result__16-img"
            />
          </div>

          <div class="result__info">
            <div class="result__description">
              <h3 class="result__secondary-title" v-html="getResult.title"></h3>
              <p class="result__text">
                {{ getResult.text }}
              </p>
            </div>

            <div v-if="getResult.type !== '<16'" class="result__buttons">
              <div class="result__restart" @click="restart()">
                <nobr>Пройти ще раз</nobr>
                <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M7.99772 2.68163C11.843 3.00874 14.8048 6.26786 14.8048 10.1703C14.8048 14.3216 11.4747 17.6864 7.36531 17.6864C3.61459 17.6864 0.512979 14.8829 0 11.2375H2.10273C2.59308 13.721 4.76258 15.5918 7.36531 15.5918C10.33 15.5918 12.731 13.1645 12.731 10.1703C12.731 7.4238 10.6943 5.10704 7.99772 4.78668V7.3616L2.98329 3.7023L7.99772 0.0415039V2.68163Z"
                      fill="#ED0000"
                  />
                </svg>
              </div>

<!--              <div class="result__remind">-->
<!--                <svg-->
<!--                    width="17"-->
<!--                    height="17"-->
<!--                    viewBox="0 0 17 17"-->
<!--                    fill="none"-->
<!--                    xmlns="http://www.w3.org/2000/svg"-->
<!--                >-->
<!--                  <path-->
<!--                      d="M14.8564 0.907715H13.935V2.43169C13.935 2.91015 13.5451 3.3 13.0844 3.3H12.2161C11.7376 3.3 11.3477 2.91015 11.3477 2.43169V0.907715H5.80118V2.43169C5.80118 2.91015 5.41132 3.3 4.95059 3.3H4.08228C3.60382 3.3 3.21397 2.91015 3.21397 2.43169V0.907715H2.31021C1.12293 0.907715 0.166016 1.88235 0.166016 3.05191V13.8969C0.166016 15.0842 1.12293 16.0411 2.31021 16.0411H14.8564C16.0437 16.0411 17.0006 15.0842 17.0006 13.8969V3.05191C17.0006 1.88235 16.0437 0.907715 14.8564 0.907715ZM15.6716 13.8969C15.6716 14.34 15.2994 14.7121 14.8564 14.7121H2.31021C1.8672 14.7121 1.49506 14.34 1.49506 13.8969V5.60368H15.6716V13.8969Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M3.62161 9.44899H5.23418C5.53543 9.44899 5.78352 9.2009 5.78352 8.89965V7.6592C5.78352 7.35795 5.53543 7.10986 5.23418 7.10986H3.62161C3.32035 7.10986 3.07227 7.35795 3.07227 7.6592V8.89965C3.07227 9.2009 3.32035 9.44899 3.62161 9.44899Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M11.9312 9.44899H13.5438C13.845 9.44899 14.0931 9.2009 14.0931 8.89965V7.6592C14.0931 7.35795 13.845 7.10986 13.5438 7.10986H11.9312C11.6299 7.10986 11.3818 7.35795 11.3818 7.6592V8.89965C11.3818 9.2009 11.6299 9.44899 11.9312 9.44899Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M7.76907 9.44899H9.38164C9.6829 9.44899 9.93098 9.2009 9.93098 8.89965V7.6592C9.93098 7.35795 9.6829 7.10986 9.38164 7.10986H7.76907C7.46782 7.10986 7.21973 7.35795 7.21973 7.6592V8.89965C7.23745 9.2009 7.46782 9.44899 7.76907 9.44899Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M3.62161 13.2415H5.23418C5.53543 13.2415 5.78352 12.9934 5.78352 12.6921V11.4517C5.78352 11.1504 5.53543 10.9023 5.23418 10.9023H3.62161C3.32035 10.9023 3.07227 11.1504 3.07227 11.4517V12.6921C3.07227 13.0111 3.32035 13.2415 3.62161 13.2415Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M11.9312 13.2415H13.5438C13.845 13.2415 14.0931 12.9934 14.0931 12.6921V11.4517C14.0931 11.1504 13.845 10.9023 13.5438 10.9023H11.9312C11.6299 10.9023 11.3818 11.1504 11.3818 11.4517V12.6921C11.3818 13.0111 11.6299 13.2415 11.9312 13.2415Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M7.76907 13.2415H9.38164C9.6829 13.2415 9.93098 12.9934 9.93098 12.6921V11.4517C9.93098 11.1504 9.6829 10.9023 9.38164 10.9023H7.76907C7.46782 10.9023 7.21973 11.1504 7.21973 11.4517V12.6921C7.23745 13.0111 7.46782 13.2415 7.76907 13.2415Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M4.08205 2.74989H4.95036C5.12756 2.74989 5.25161 2.60813 5.25161 2.44864V0.393048C5.25161 0.215841 5.10984 0.0917969 4.95036 0.0917969H4.08205C3.90484 0.0917969 3.7808 0.233562 3.7808 0.393048V2.43092C3.76307 2.60813 3.90484 2.74989 4.08205 2.74989Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                  <path-->
<!--                      d="M12.2153 2.74989H13.0836C13.2608 2.74989 13.3849 2.60813 13.3849 2.44864V0.393048C13.3849 0.215841 13.2431 0.0917969 13.0836 0.0917969H12.2153C12.0381 0.0917969 11.9141 0.233562 11.9141 0.393048V2.43092C11.9141 2.60813 12.0558 2.74989 12.2153 2.74989Z"-->
<!--                      fill="#ED0000"-->
<!--                  />-->
<!--                </svg>-->
<!--                <p>-->
<!--                  Нагадувати<br/>-->
<!--                  мені щороку-->
<!--                </p>-->
<!--              </div>-->
            </div>

            <div class="result__buttons" v-if="getResult.type === '<16'">
              <div class="result__restart" @click="restart()">
                <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M13.3445 11.1912L8.78012 10.7949L8.78061 12.8521C8.78061 13.2462 8.35928 13.5007 8.00933 13.3127L0.492506 9.25917C0.126375 9.06311 0.126183 8.53514 0.492494 8.339L8.00923 4.28569C8.35639 4.09768 8.78051 4.35062 8.78051 4.74625L8.78008 6.80346L13.3444 6.40724C13.6454 6.38144 13.9127 6.61718 13.9127 6.92837L13.9128 10.6701C13.9128 10.9749 13.6511 11.2175 13.3445 11.1912Z"
                      fill="#ED0000"
                  />
                </svg>
                <nobr>Назад</nobr>
              </div>

              <div class="result__remind result__remind--16" @click="restart()">
                <p>Пройти ще раз</p>
                <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M7.99772 2.68163C11.843 3.00874 14.8048 6.26786 14.8048 10.1703C14.8048 14.3216 11.4747 17.6864 7.36531 17.6864C3.61459 17.6864 0.512979 14.8829 0 11.2375H2.10273C2.59308 13.721 4.76258 15.5918 7.36531 15.5918C10.33 15.5918 12.731 13.1645 12.731 10.1703C12.731 7.4238 10.6943 5.10704 7.99772 4.78668V7.3616L2.98329 3.7023L7.99772 0.0415039V2.68163Z"
                      fill="#ED0000"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="result__item" v-if="getResult.dop">
          <div class="result__pack result__pack--dop">
            <img
                srcset="
                @/assets/img/other_pack-3(x2).png 1024w,
                @/assets/img/other_pack-3(x3).png 1920w
              "
                sizes="(max-width: 640px) 200px,
                          (max-width: 1024px) 250px,
                          272px"
                src="@/assets/img/other_pack-3(x1).png"
                alt=""
                decoding="async"
                loading="lazy"
                class="result__img"
            />

            <div class="result__buttons">
              <a
                  href="https://tabletki.ua/Cito-Test-HIV-1-2/1001639/"
                  class="result__link"
                  target="_blank"
              >
                <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M16.4159 13.5961L12.1097 9.30167C12.5618 8.41332 12.8065 7.42751 12.8065 6.40243C12.8065 4.69219 12.1399 3.08432 10.9309 1.87529C9.72181 0.665889 8.11353 0 6.40327 0C4.69339 0 3.08474 0.665889 1.8757 1.87529C0.666654 3.08469 0 4.69257 0 6.40281C0 8.11304 0.666276 9.72092 1.8757 10.9299C3.08474 12.1393 4.69302 12.8052 6.4029 12.8052C7.42799 12.8052 8.41419 12.5612 9.3018 12.1095L13.5967 16.4161C13.9854 16.8047 14.4964 17 15.0063 17C15.5162 17 16.0265 16.8047 16.4155 16.4161C17.1948 15.6369 17.1948 14.375 16.4159 13.5961ZM3.56707 9.23822C2.80977 8.48055 2.39278 7.47397 2.39278 6.40281C2.39278 5.33164 2.80977 4.32469 3.56745 3.56702C4.32475 2.80973 5.33172 2.39237 6.4029 2.39237C7.47407 2.39237 8.48104 2.80935 9.23872 3.56702C9.99603 4.32431 10.4134 5.33127 10.4134 6.40243C10.4134 7.47359 9.99603 8.48055 9.23872 9.23822C8.48104 9.99551 7.4737 10.4125 6.4029 10.4125C5.3321 10.4125 4.32513 9.99589 3.56707 9.23822Z"
                      fill="#F8391F"
                  />
                </svg>
                <p>
                  <nobr>Знайти в аптеці</nobr>
                </p>
              </a>
              <a
                  href="http://liki24.com/index.php?route=checkout/custom_cart&cart_id=173229&utm_source=farmasko&utm_medium=partner&utm_campaign=liki_ua_farmasko_tests&utm_content=hiv"
                  class="result__btn"
                  target="_blank"
              >
                <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M25.7692 20.3262L21.4286 16.6792L23.2291 14.8795C23.5739 14.5347 23.4279 13.9434 22.9572 13.8017L12.8332 10.7713C12.3412 10.6225 11.8791 11.0843 12.028 11.5764L15.0585 21.7002C15.1978 22.1685 15.7902 22.3183 16.1364 21.9721L17.936 20.1716L21.5832 24.5121C21.824 24.7981 22.2641 24.8257 22.5364 24.5534L25.8105 21.2794C26.0772 21.0128 26.0604 20.5715 25.7692 20.3262Z"
                      fill="#f3e6a1"
                  />
                  <path
                      d="M5.00297 8.58325L5.00292 8.58323L4.03358 8.27355L5.00297 8.58325ZM5.00297 8.58325C5.02699 8.59092 5.0369 8.60095 5.04252 8.60842C5.04988 8.61821 5.05666 8.63362 5.05825 8.65342C5.05985 8.67329 5.05562 8.69037 5.04948 8.70215C5.04461 8.7115 5.0362 8.72268 5.01545 8.73338C4.99625 8.74328 4.9749 8.74551 4.95308 8.73855L3.98381 8.42889C3.94094 8.4152 3.91728 8.36936 3.93093 8.32649M5.00297 8.58325L3.93093 8.32649M3.93093 8.32649C3.93094 8.32647 3.93095 8.32644 3.93096 8.32642C3.94473 8.28349 3.99054 8.25986 4.0335 8.27353L3.93093 8.32649Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M9.36074 3.10274L9.36076 3.10278L9.91562 3.95582L9.36074 3.10274ZM9.36074 3.10274C9.34699 3.0816 9.3347 3.0747 9.326 3.07125C9.31463 3.06674 9.29797 3.06427 9.27845 3.06797C9.25886 3.07168 9.24351 3.08027 9.23377 3.08931C9.22605 3.09648 9.21748 3.10754 9.21265 3.13038C9.20817 3.15151 9.21166 3.1727 9.22414 3.19189L9.77895 4.04487C9.80349 4.08259 9.85395 4.0933 9.89169 4.0688M9.36074 3.10274L9.89169 4.0688M9.89169 4.0688C9.89171 4.06879 9.89173 4.06877 9.89175 4.06876C9.92951 4.04414 9.9402 3.9937 9.91566 3.95589L9.89169 4.0688Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M17.0953 1.7559L17.0953 1.75595L16.8588 2.74569L17.0953 1.7559ZM17.0953 1.7559C17.1012 1.73138 17.0977 1.71771 17.0942 1.70903C17.0897 1.69768 17.08 1.6839 17.0639 1.67229C17.0477 1.66064 17.0309 1.65542 17.0176 1.65458C17.0071 1.65392 16.9932 1.65532 16.9733 1.66754C16.9549 1.67885 16.9419 1.69597 16.9366 1.71824L16.7001 2.7079C16.6896 2.75167 16.7166 2.79563 16.7603 2.80613M17.0953 1.7559L16.7603 2.80613M16.7603 2.80613C16.7604 2.80614 16.7604 2.80614 16.7604 2.80615C16.8043 2.81658 16.8482 2.7896 16.8587 2.74577L16.7603 2.80613Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M24.1405 4.27334L24.1404 4.27338L23.5312 5.08848L24.1405 4.27334ZM24.1405 4.27334C24.1556 4.25314 24.1578 4.23922 24.1581 4.22987C24.1584 4.21764 24.1549 4.20115 24.1447 4.1841C24.1345 4.167 24.1211 4.15556 24.1093 4.14953C24.0999 4.14476 24.0865 4.14053 24.0634 4.14387C24.0421 4.14697 24.0234 4.15755 24.0097 4.17589L23.4005 4.99093C23.3736 5.02698 23.3809 5.07803 23.4169 5.105M24.1405 4.27334L23.4169 5.105M23.4169 5.105C23.417 5.10502 23.417 5.10503 23.417 5.10505C23.4531 5.132 23.5042 5.12462 23.5312 5.08854L23.4169 5.105Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M3.20257 16.0265L3.20262 16.0265L4.21537 15.9271L3.20257 16.0265ZM3.20257 16.0265C3.17748 16.029 3.16441 16.0237 3.15629 16.019C3.14566 16.013 3.13333 16.0015 3.12403 15.984C3.11469 15.9663 3.11181 15.949 3.11279 15.9357C3.11356 15.9252 3.11685 15.9116 3.13167 15.8936C3.14538 15.8769 3.16411 15.8664 3.18689 15.8641L4.19956 15.7647C4.24435 15.7603 4.28422 15.793 4.28866 15.8378M3.20257 16.0265L4.28866 15.8378M4.28866 15.8378C4.28866 15.8378 4.28866 15.8379 4.28867 15.8379C4.29302 15.8828 4.2603 15.9226 4.21545 15.9271L4.28866 15.8378Z"
                      stroke="#f3e6a1"
                  />
                  <path
                      d="M6.98121 22.9082L6.98125 22.9081L7.60648 22.1052L6.98121 22.9082ZM6.98121 22.9082C6.96572 22.9281 6.95289 22.9339 6.94394 22.9366C6.93222 22.9401 6.91541 22.9412 6.89627 22.9358C6.87707 22.9305 6.86251 22.9206 6.85357 22.9108C6.84648 22.903 6.83889 22.8912 6.836 22.8681C6.83334 22.8466 6.83862 22.8258 6.85268 22.8077L7.47786 22.0049C7.50551 21.9694 7.55669 21.963 7.59222 21.9906M6.98121 22.9082L7.59222 21.9906M7.59222 21.9906C7.59224 21.9906 7.59226 21.9907 7.59228 21.9907C7.62782 22.0184 7.63418 22.0696 7.60653 22.1052L7.59222 21.9906Z"
                      stroke="#f3e6a1"
                  />
                </svg>
                <p>
                  Купити<br/>
                  онлайн
                </p>
              </a>
            </div>
          </div>

          <div class="result__description">
            <h3 class="result__secondary-title">
              Додатково бажано перевіритися вдома також на ВІЛ-інфекцію
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Result",
  data: () => ({}),
  computed: {
    ...mapGetters(["getResult"]),
  },
  methods: {
    ...mapActions(['clearTest']),

    restart() {
      this.clearTest()
      this.$router.push('/test')
    },
  },

  mounted() {
    window.scroll(0, 0)
  },
};
</script>

<style scoped lang="scss">
.result {
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 20px;

  background-color: #c83b26;

  @media screen and (min-width: 768px) {
    padding-top: 150px;
  }

  @media screen and (min-width: 1200px) {
    padding-bottom: 35px;
  }

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    margin-bottom: 90px;

    @media screen and (min-width: 1200px) {
      margin-bottom: 0;
    }
  }

  &__title {
    font-family: "Oswald", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;

    text-transform: uppercase;

    color: #000000;

    @media screen and (min-width: 768px) {
      font-size: 36px;
      line-height: 48px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 48px;
      line-height: 48px;
    }

    @media screen and (min-width: 1920px) {
      font-size: 72px;
      line-height: 88px;
    }
  }

  &__risk {
    display: flex;
    align-items: center;

    height: 20px;
    width: 215px;

    margin-bottom: 5px;

    @media screen and (min-width: 768px) {
      width: 70%;
      height: 30px;
    }

    @media screen and (min-width: 1920px) {
      width: 60%;
    }

    &-item {
      position: relative;

      height: 100%;

      p {
        display: none;
      }

      &.active {
        border: 1px solid #000;

        p {
          position: absolute;
          bottom: 60%;
          left: 0;

          display: flex;
          justify-content: center;

          height: 50px;

          padding: 15px 12px;

          background-image: url(../assets/img/risk-buble.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          font-family: "Oswald";
          font-weight: bold;
          font-size: 12px;
          line-height: 9px;
          letter-spacing: 0.02em;
          text-transform: uppercase;

          @media screen and (min-width: 768px) {
            height: 60px;
            padding: 20px 18px;
          }

          @media screen and (min-width: 1920px) {
            height: 80px;
            padding: 20px 25px;

            font-size: 24px;
            line-height: 18px;
          }

          &::after {
            content: "";

            position: absolute;
            top: 49px;
            left: 30%;

            width: 6px;
            height: 6px;

            background: #fff;
            border-radius: 50%;

            @media screen and (min-width: 768px) {
              width: 8px;
              height: 8px;
              top: 59px;
            }

            @media screen and (min-width: 1920px) {
              top: 79px;
              left: 33%;
            }
          }
        }
      }
    }

    &-low {
      width: 25%;

      background: #accc56;
      border-radius: 60px 0 0 60px;

      p {
        color: #accc56;
      }
    }

    &-medium {
      width: 30%;

      background: #ffab2e;

      p {
        color: #ffab2e;
      }
    }

    &-high {
      width: 45%;

      background: #f8391f;
      border-radius: 0 60px 60px 0;

      p {
        color: #f8391f;
      }
    }
  }

  &__content {
  }

  &__item {
    margin-bottom: 70px;
    padding: 65px 24px 30px;

    background: #f3e6a1;
    border: 1px solid #000000;
    border-radius: 13px;

    @media screen and (min-width: 1200px) {
      display: flex;

      padding: 40px 50px 32px 70px;
      margin-top: 12vh;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.mt {
      margin-top: 200px;
    }
  }

  &__img {
    width: 70%;
    max-width: 200px;
    height: auto;

    margin-top: -150px;

    //box-shadow: 6px 16px 48px rgba(82, 25, 1, 0.36);

    @media screen and (min-width: 768px) {
      max-width: 280px;
    }

    @media screen and (min-width: 1200px) {
      width: 100%;

      margin: 0 0px 20px 0;
    }

    &--bc {
      width: 80%;
      max-width: 340px;

      @media screen and (min-width: 1200px) {
        width: 400px;

        margin: -70px -50px 20px 0;
      }
    }
  }

  &__16-img {
    margin-top: -110px;

    @media screen and (min-width: 1200px) {
      max-width: unset;
      width: 450px;

      margin-top: -100px;
      padding-right: 40px;
    }
  }

  &__buble {
    margin: -100px 0 0;


    &-img {
      display: block;
      margin: -210px auto 0 auto;

      @media screen and (min-width: 1200px) {
        min-width: 350px;
        margin: -100px 0 0 -40px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 32px;
  }

  &__pack {
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media screen and (min-width: 1200px) {
      flex-direction: column;
      margin-bottom: 0;
      margin-right: 80px;
    }

    .result__buttons {
      margin-top: 0;
    }

    &--dop {
      .result__img {
        @media screen and (min-width: 1200px) {
          margin: -100px 0px 20px 0;
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;

    width: 100%;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    @media screen and (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }

    svg {
      min-width: 14px;
    }

    p {
      position: relative;

      margin-left: 5px;

      transition: 0.4s;

      &::after {
        content: "";

        position: absolute;
        top: 105%;
        left: 0;

        width: 100%;
        height: 1px;

        background-color: #000;

        transition: 0.4s;
      }
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        p {
          &::after {
            width: 20%;
          }
        }
      }
    }

    &:active {
      p {
        color: #ed0000;

        &::after {
          width: 20%;

          background-color: #ed0000;
        }
      }
    }
  }

  &__btn {
    position: relative;
    transition: 0.4s;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 48px;

    padding: 0 35px 0 22px;
    margin-left: 20px;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    background: #f8391f;
    border: 1px solid #000000;
    border-radius: 120px;

    color: #fff;

    @media screen and (min-width: 1200px) {
      height: 64px;
      max-width: 180px;

      font-size: 16px;
      line-height: 18px;
    }

    &::after {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      background-color: #ed0000;

      transition: 0.4s;
    }

    &::before {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      border: 1px solid transparent;

      transition: top 0.4s, right 0.4s;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        &::before {
          top: 10px;
          right: -5px;

          border-color: #000;
        }
      }
    }

    &:active {
      background-color: rgba(200, 59, 38, 1);
      color: white;

      &::after {
        background-color: rgba(200, 59, 38, 1);
      }

      &::before {
        top: 0;
        right: 0;
      }

      svg {
        path {
          &:first-child {
            fill: #f3e6a1;
            stroke: none;
          }

          stroke: white;
        }
      }
    }

    svg {
      position: relative;
      z-index: 1;

      margin-right: 8px;
      min-width: 20px;

      path {
        transition: 0.4s;
      }
    }

    p {
      position: relative;
      z-index: 1;
    }
  }

  &__info {
    @media screen and (min-width: 1200px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__secondary-title {
    margin-bottom: 13px;

    font-family: "Oswald", sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;

    color: #000000;

    @media screen and (min-width: 1200px) {
      margin-bottom: 24px;

      font-size: 32px;
      line-height: 40px;
    }

    @media screen and (min-width: 1980px) {
      font-size: 48px;
      line-height: 46px;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    @media screen and (min-width: 1200px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media screen and (min-width: 1980px) {
      font-size: 24px;
      line-height: 22px;
    }
  }

  &__restart {
    display: flex;
    align-items: center;

    width: 50%;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    color: #ed0000;

    cursor: pointer;
    transition: 0.4s;

    @media screen and (min-width: 1200px) {
      font-size: 16px;
      line-height: 18px;
    }

    svg {
      margin: 0 5px;

      path {
        transition: 0.4s;
      }
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        color: #000;

        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }

  &__remind {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 148px;
    height: 48px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #ed0000;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 120px;

    cursor: pointer;

    @media screen and (min-width: 1200px) {
      height: 64px;
      width: 180px;

      font-size: 16px;
      line-height: 18px;
    }

    &--16 {
      width: 190px;
    }

    svg {
      margin: 0 8px;
    }

    &::after {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      background-color: #fff;

      transition: 0.4s;
    }

    &::before {
      content: "";

      position: absolute;
      right: 0;
      top: 0;

      width: 100%;
      height: 100%;

      border-radius: 120px;
      border: 1px solid transparent;

      transition: top 0.4s, right 0.4s;
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        &::before {
          top: 10px;
          right: -5px;

          border-color: #000;
        }
      }
    }

    &:active {
      background-color: #ed0000;
      color: #fff;

      &::after {
        background-color: #ed0000;
      }

      &::before {
        top: 0;
        right: 0;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }

    svg {
      position: relative;
      z-index: 1;

      margin-right: 8px;
      min-width: 20px;

      path {
        transition: 0.4s;
      }
    }

    p {
      position: relative;
      z-index: 1;
    }
  }
}
</style>
